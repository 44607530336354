import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: {
    datoCmsPage: { seoMetaTags, title, bannerImage, modularBlocks, noIndex },
  },
}) => {
  return (
    <Layout noIndex={noIndex}>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} image={bannerImage} />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        ...BannerImageFragment
      }
      modularBlocks {
        ...BestModuleCalculatorModularBlockFragment
        ...CaseStudiesModularBlockFragment
        ...ContactCallToActionModularBlockFragment
        ...ContentModularBlockFragment
        ...DemoCallToActionModularBlockFragment
        ...DemoFormModularBlockFragment
        ...DotsDividerModularBlockFragment
        ...DownloadFormModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedStepsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...GartnerContentModularBlockFragment
        ...IconCallToActionsModularBlockFragment
        ...ImageLargeModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageModularBlockFragment
        ...MiniCaseStudiesModularBlockFragment
        ...MoleculesDividerModularBlockFragment
        ...NewsInsightsModularBlockFragment
        ...PricingPlansModularBlockFragment
        ...ProductsServicesModularBlockFragment
        ...StatisticsModularBlockFragment
        ...TeamProfilesModularBlockFragment
        ...TestimonialModularBlockFragment
        ...TestimonialsModularBlockFragment
        ...TestimonialsSliderModularBlockFragment
        ...VideoContentModularBlockFragment
        ...VideoModularBlockFragment
        ...VideosModularBlockFragment
      }
      noIndex
    }
  }
`;

export default DefaultPageTemplate;
